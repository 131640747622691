import React from "react";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import styled, {
  createGlobalStyle,
  css,
  keyframes,
} from "styled-components/macro";
import * as ReactMarkdown from "react-markdown";
import SEO from "../components/seo";
import Favicon from "../components/favicon";
import VarausFormi from "../components/VarausFormi";
import Trigger from "../components/trigger";
import Footer from "../components/footer";
import { useSitemap } from "../components/sitemap";
import { Menu } from "../components/menu";
import CookiePopup from "../components/CookiePopup";

/*
https://github.com/gatsbyjs/gatsby/issues/20493
*/

const FluidImg = ({ url, fluid, ...rest }) => {
  if (fluid !== null) return <Img fluid={fluid} {...rest} />;
  return (
    <img src={url} style={{ width: "100%", maxWidth: "100%" }} {...rest} />
  );
};

export const SitemapContext = React.createContext<
  Array<{
    id: string;
    title: string;
    path: string;
    locale: string;
    published: boolean;
  }>
>([]);

export const SiteLanguageContext = React.createContext<string>("en");

const GlobalStyles = createGlobalStyle`
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  background: #000000;
}
h2, h3, h4 {
  font-weight: 300;
}
h3 {
  font-size: 42px;
  line-height: 52px;
  margin: 0;
}
p {
  font-size: 18px;
  line-height: 28px;
}
a {
  text-decoration: none;
  color: #00e;
}
`;

export const Container = styled.div`
  max-width: 1176px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
`;

export const FramianLogo = () => (
  <svg
    width="149"
    height="16"
    viewBox="0 0 149 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ paddingTop: 16, paddingBottom: 16 }}
  >
    <g fill="currentColor">
      <path d="M99.0414 0H95.9402V16H99.0414V0Z" />
      <path d="M32.7778 10.3117L33.0091 10.1973C34.9329 9.25732 36.0367 7.52309 36.0367 5.43854C36.0367 2.18513 33.4751 0 29.6626 0H21.0529V16H24.1541V10.9014H29.582L33.2193 16H36.5658L36.734 15.7676L32.7778 10.3117ZM29.54 8.11966H24.1541V2.75742H29.54C31.5654 2.75742 32.981 3.86039 32.981 5.43854C32.981 6.99241 31.5338 8.11966 29.54 8.11966Z" />
      <path d="M12.3486 8.16822H3.10117V2.75742H13.4454V0H0V16H3.10117V10.9014H12.3486V8.16822Z" />
      <path d="M54.1987 0H49.335L42.4108 16H45.6907L47.1799 12.3408H56.3363L57.8431 16H61.0984L54.1987 0ZM48.2907 9.60416L51.08 2.75396H52.3906L55.2079 9.60416H48.2907Z" />
      <path d="M87.348 16V0H83.553L78.0024 8.65727L72.4448 0H68.5797V16H71.6809V3.90895L77.0878 12.3373H78.9065L84.2468 4.03729V16H87.348Z" />
      <path d="M118.314 0H113.45L106.526 16H109.806L111.295 12.3408H120.452L121.958 16H125.214L118.314 0ZM112.41 9.60416L115.199 2.75396H116.51L119.327 9.60416H112.41Z" />
      <path d="M149 16V0H145.895V11.4459L136.077 0H132.695V16H135.8V4.46391L145.871 16H149Z" />
    </g>
  </svg>
);

const Hero_DescriptionWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 11;
  grid-row-start: 13;
  grid-row-end: 33;
  color: #ffffff;
  line-height: 36px;
  font-size: 24;
  > p {
    margin: 0;
    font-size: 24px;
    line-height: 36px;
  }
`;
const Hero_Background: React.FC = () => {
  const fontSize = 24;
  const parentRef = React.useRef<HTMLDivElement>(null);
  const divRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const { current } = divRef;
    if (current === null) return;
    let frame: any;
    const tick = () => {
      var boxes = ["╱", "╲"];
      current.innerText += boxes[Math.floor(Math.random() * boxes.length)];
      current.innerText += boxes[Math.floor(Math.random() * boxes.length)];
      if (
        current.getBoundingClientRect().height >
        parentRef.current!.getBoundingClientRect().height +
          fontSize +
          fontSize * 2
      )
        return;
      frame = requestAnimationFrame(tick);
    };
    tick();
    return () => cancelAnimationFrame(frame);
  }, []);
  React.useEffect(() => {
    const scroll = () => {
      const off = Math.min(
        2 * fontSize,
        (window.scrollY / window.innerHeight) * 2 * fontSize,
      );
      divRef.current!.style.transform = `translateY(-${off}px)`;
    };
    window.addEventListener("scroll", scroll, false);
    return () => window.removeEventListener("scroll", scroll, false);
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        wordWrap: "break-word",
        pointerEvents: "none",
        zIndex: 0,
        overflow: "hidden",
      }}
      ref={parentRef}
    >
      <div
        style={{
          color: "#222",
          fontSize,
          fontWeight: 900,
          lineHeight: "1em",
        }}
        ref={divRef}
      />
    </div>
  );
};
const Hero: React.FC<{ description: string; homePath: string }> = ({
  description,
  homePath,
}) => {
  return (
    <>
      <div style={{ background: "#000", position: "relative" }}>
        <Hero_Background />
        <Container style={{ position: "relative", zIndex: 1 }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(12, 1fr)",
              gridTemplateRows: "repeat(32, 1fr)",
              minHeight: "100vh",
              gridGap: "0px 24px",
            }}
          >
            <div
              style={{
                gridColumnStart: 1,
                gridColumnEnd: 13,
                gridRowStart: 1,
                gridRowEnd: 13,
              }}
            >
              <Link
                to={homePath}
                style={{
                  display: "inline-block",
                  color: "#fff",
                }}
                title="Framian"
              >
                <FramianLogo />
              </Link>
            </div>
            <Hero_DescriptionWrapper
              style={{
                gridColumnStart: 3,
                gridColumnEnd: 11,
                gridRowStart: 13,
                gridRowEnd: 33,
                color: "#ffffff",
                lineHeight: "36px",
                fontSize: 24,
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

const SubpageHero_DescriptionWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 11;
  grid-row-start: 13;
  grid-row-end: 33;
  color: #000;
  line-height: 36px;
  font-size: 24px;
  display: flex;
  align-items: center;
  > h1 {
    margin: 0;
    font-size: 32px;
    line-height: normal;
    margin-top: 2em;
  }
`;
const SubpageHero: React.FC<{ title: string }> = ({ title }) => {
  return (
    <>
      <div style={{ background: "#fff" }}>
        <Container style={{ paddingBottom: 0, marginBottom: -10 }}>
          <div style={{ display: "flex" }}>
            <Link to="/">
              <svg
                width="149"
                height="16"
                viewBox="0 0 149 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: 16 }}
              >
                <g fill="black">
                  <path d="M99.0414 0H95.9402V16H99.0414V0Z" />
                  <path d="M32.7778 10.3117L33.0091 10.1973C34.9329 9.25732 36.0367 7.52309 36.0367 5.43854C36.0367 2.18513 33.4751 0 29.6626 0H21.0529V16H24.1541V10.9014H29.582L33.2193 16H36.5658L36.734 15.7676L32.7778 10.3117ZM29.54 8.11966H24.1541V2.75742H29.54C31.5654 2.75742 32.981 3.86039 32.981 5.43854C32.981 6.99241 31.5338 8.11966 29.54 8.11966Z" />
                  <path d="M12.3486 8.16822H3.10117V2.75742H13.4454V0H0V16H3.10117V10.9014H12.3486V8.16822Z" />
                  <path d="M54.1987 0H49.335L42.4108 16H45.6907L47.1799 12.3408H56.3363L57.8431 16H61.0984L54.1987 0ZM48.2907 9.60416L51.08 2.75396H52.3906L55.2079 9.60416H48.2907Z" />
                  <path d="M87.348 16V0H83.553L78.0024 8.65727L72.4448 0H68.5797V16H71.6809V3.90895L77.0878 12.3373H78.9065L84.2468 4.03729V16H87.348Z" />
                  <path d="M118.314 0H113.45L106.526 16H109.806L111.295 12.3408H120.452L121.958 16H125.214L118.314 0ZM112.41 9.60416L115.199 2.75396H116.51L119.327 9.60416H112.41Z" />
                  <path d="M149 16V0H145.895V11.4459L136.077 0H132.695V16H135.8V4.46391L145.871 16H149Z" />
                </g>
              </svg>
            </Link>
            <h1
              style={{
                margin: 0,
                marginTop: 3,
                whiteSpace: "pre",
                fontWeight: 300,
              }}
            >
              {" / "}
            </h1>
            <h1 style={{ margin: 0, marginTop: 3 }}>{title}</h1>
          </div>
        </Container>
      </div>
    </>
  );
};
const DatoLink: React.FC<any> = ({ id, children, ...rest }) => {
  const sitemap = React.useContext(SitemapContext);
  return (
    <Link to={sitemap.find(v => v.id === id)?.path} {...rest}>
      {children}
    </Link>
  );
};

const Intro_ContentWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 11;
  @media (max-width: 768px) {
    grid-column-start: 2;
    grid-column-end: 12;
  }
  p {
    font-size: 24px;
    line-height: 36px;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  h2 {
    font-size: 42px;
    line-height: 52px;
    margin: 0;
  }
  p {
    word-break: break-word;
  }
`;
const Intro: React.FC<{ text: string; link: any; linkText: string }> = ({
  text,
  link,
  linkText,
}) => {
  return (
    <Container>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gridGap: "0px 24px",
        }}
      >
        <Intro_ContentWrapper
          dangerouslySetInnerHTML={{ __html: text }}
        ></Intro_ContentWrapper>
        {linkText !== "" && link !== null && (
          <Intro_ContentWrapper style={{ marginTop: 50 }}>
            <DatoLink
              id={link.id}
              style={{
                fontSize: 16,
                lineHeight: "auto",
                fontFamily: "'Roboto Mono', monospace",
                color: "#000",
              }}
            >
              _{linkText}
            </DatoLink>
          </Intro_ContentWrapper>
        )}
      </div>
    </Container>
  );
};

export const Heading: React.FC<{ invert?: boolean; title?: string }> = ({
  invert,
  title,
}) => {
  return (
    <>
      <div style={{ paddingTop: 1 }} />
      <div
        style={{
          height: 0,
          marginTop: 119,
          marginBottom: 118,
        }}
      >
        <div
          style={{
            transform: "translateY(-50%)",
            maxWidth: 1176,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                borderTop: `1px solid ${invert ? "#ffffff" : "#000000"}`,
                height: 1,
                width: 24,
              }}
            />
            {typeof title !== "undefined" && title !== null && title !== "" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 2,
                }}
              >
                <div
                  style={{
                    fontFamily: "'Roboto Mono', monospace",
                    fontSize: 16,
                    color: invert ? "#fff" : "#000",
                    padding: "0 24px",
                  }}
                >
                  {title}
                </div>
              </div>
            )}
            <div
              style={{
                flex: "1",
                minWidth: 24,
                borderTop: `1px solid ${invert ? "#ffffff" : "#000000"}`,
                height: 1,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const BlackBox: React.FC<{
  title: string;
  description: string;
  link: any;
  linkText: string;
  large?: boolean;
  style?: any;
}> = ({ title, description, link, linkText, large, style }) => (
  <div
    style={{
      background: "#000000",
      color: "#ffffff",
      flex: "1",
      padding: 48,
      ...(typeof style !== "undefined" ? style : {}),
    }}
  >
    <h3
      style={{
        fontSize: 42,
        lineHeight: "52px",
        margin: 0,
        ...(large ? { marginBottom: "1.25em" } : {}),
        hyphens: "auto",
      }}
    >
      {title}
    </h3>
    <p
      style={{
        fontWeight: 300,
        ...(large && link !== null ? { marginBottom: 100 } : {}),
      }}
    >
      {description}
    </p>
    {link !== null &&
      (link["__typename"] == "DatoCmsPage" ||
        typeof link["__typename"] === "undefined") && (
        <DatoLink
          id={link.id}
          style={{
            fontFamily: "'Roboto Mono', monospace",
            fontSize: 16,
            lineHeight: "19px",
            color: "#fff",
          }}
        >
          _{linkText}
        </DatoLink>
      )}
    {link !== null && link["__typename"] == "DatoCmsBlog" && (
      <DatoLink
        id={link.id}
        style={{
          fontFamily: "'Roboto Mono', monospace",
          fontSize: 16,
          lineHeight: "19px",
          color: "#fff",
        }}
      >
        _{linkText}
      </DatoLink>
    )}
    {link !== null && link["__typename"] == "DatoCmsExternalLink" && (
      <a
        href={link.link}
        style={{
          fontFamily: "'Roboto Mono', monospace",
          fontSize: 16,
          lineHeight: "19px",
          color: "#fff",
        }}
      >
        _{linkText}
      </a>
    )}
  </div>
);

export const useIsMobile = () => {
  const [matches, setMatches] = React.useState(false);
  React.useLayoutEffect(() => {
    const mql = window.matchMedia("(max-width: 768px)");
    const cb = () => {
      setMatches(mql.matches);
    };
    mql.addListener(cb);
    setMatches(mql.matches);
    return () => {
      mql.removeListener(cb);
    };
  }, []);
  return matches;
};

const RefBlock: React.FC<any> = ({
  titleOnTheRight,
  title,
  description,
  linkText,
  link,
  image,
}) => {
  const isMobile = useIsMobile();
  if (isMobile)
    return (
      <Container>
        <Img
          fluid={image.fluid}
          alt={image.alt}
          title={image.title}
          style={{ marginBottom: 24 }}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gridGap: "0px 24px",
          }}
        >
          <BlackBox
            title={title}
            description={description}
            link={link}
            linkText={linkText}
            style={{
              gridColumn: titleOnTheRight ? "2 / 13" : "1 / 12",
              marginBottom: 24,
            }}
          />
        </div>
      </Container>
    );
  return (
    <Container>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gridGap: "0px 24px",
        }}
      >
        <div
          style={{
            ...(!titleOnTheRight
              ? {
                  gridColumnStart: 3,
                  gridColumnEnd: 13,
                }
              : {
                  gridColumnStart: 1,
                  gridColumnEnd: 11,
                }),
            gridRowStart: 1,
            gridRowEnd: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ flex: "1" }}>
            <Img fluid={image.fluid} alt={image.alt} title={image.title} />
          </div>
        </div>
        <div
          style={{
            ...(titleOnTheRight
              ? {
                  gridColumnStart: 9,
                  gridColumnEnd: 13,
                }
              : {
                  gridColumnStart: 1,
                  gridColumnEnd: 5,
                }),
            gridRowStart: 1,
            gridRowEnd: 2,
            display: "flex",
            alignItems: "flex-end",
            marginTop: 48,
            marginBottom: 48,
            zIndex: 1,
          }}
        >
          <BlackBox
            title={title}
            description={description}
            link={link}
            linkText={linkText}
          />
        </div>
      </div>
    </Container>
  );
};

const Testimonial_ContentWrapper = styled.div`
  p {
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
  }
`;
const Testimonial: React.FC<{
  quoteText: string;
  titleOnTheRight: boolean;
  quotePerson: any;
}> = ({ quoteText, titleOnTheRight, quotePerson }) => {
  return (
    <Container>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gridGap: "0px 24px",
        }}
      >
        <div style={{ gridColumnStart: 3, gridColumnEnd: 11 }}>
          <Testimonial_ContentWrapper
            dangerouslySetInnerHTML={{ __html: quoteText }}
          />
          {quotePerson !== null && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    background: "#c4c4c4",
                    width: 72,
                    height: 72,
                    marginRight: 24,
                    borderRadius: 36,
                    overflow: "hidden",
                  }}
                >
                  <Img
                    fluid={quotePerson.personImage.fluid}
                    alt={quotePerson.personImage.alt}
                    title={quotePerson.personImage.title}
                  />
                </div>
              </div>
              <div style={{ flex: "1" }}>
                <p
                  style={{
                    fontFamily: "'Roboto Mono', monospace",
                    fontSize: 16,
                    lineHeight: "24px",
                  }}
                >
                  {quotePerson.personName}
                  <br />
                  {quotePerson.personTitleCompany}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

const LogoWall_Grid = styled.div<{ length: number }>`
  display: grid;
  grid-template-columns: repeat(${({ length }) => length * 3}, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 0 24px;
  ${({ length }) => css`
    ${Array.from({ length })
      .map(
        (_, k) => `
> div:nth-child(${1 + k * 2}) {
  grid-column-start: ${1 + k * 3};
  grid-column-end: ${4 + k * 3};
  grid-row-start: 1;
  grid-row-end: 2;
}
> div:nth-child(${1 + k * 2 + 1}) {
  grid-column-start: ${1 + k * 3};
  grid-column-end: ${3 + k * 3};
  grid-row-start: 2;
  grid-row-end: 3;
}
`,
      )
      .join("")}
  `}
`;

const LogoWall: React.FC<any> = ({ logoBoxes }) => {
  const isMobile = useIsMobile();
  const paginationSize = 4;
  if (isMobile)
    return (
      <Container>
        <div
          style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)" }}
        >
          {logoBoxes.map((v, k, self) => {
            return (
              <React.Fragment key={k}>
                <div style={{ gridColumn: "3 / 11" }}>
                  <h4
                    style={{
                      marginTop: 0,
                      fontSize: 24,
                      lineHeight: "36px",
                    }}
                  >
                    {v.title}
                  </h4>
                  <p style={{ fontWeight: 300 }}>{v.description}</p>
                </div>
                <div
                  style={{
                    gridColumn: "3 / 9",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: k !== self.length - 1 ? 100 : undefined,
                  }}
                >
                  <div style={{ flex: "1" }}>
                    {v.logo !== null && <FluidImg {...v.logo} />}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    );
  return (
    <Container>
      {Array.from({
        length: Math.ceil(logoBoxes.length / paginationSize),
      }).map((_, startk, self) => (
        <LogoWall_Grid
          key={startk}
          length={paginationSize}
          style={{
            marginBottom: startk < self.length - 1 ? 80 : undefined,
          }}
        >
          {logoBoxes.map((v, k) => {
            if (
              k >= startk * paginationSize &&
              k < startk * paginationSize + paginationSize
            )
              return (
                <React.Fragment key={k}>
                  <div>
                    <h4
                      style={{
                        marginTop: 0,
                        fontSize: 24,
                        lineHeight: "36px",
                      }}
                    >
                      {v.title}
                    </h4>
                    <p style={{ fontWeight: 300 }}>{v.description}</p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: "1" }}>
                      {v.logo !== null && <FluidImg {...v.logo} />}
                    </div>
                  </div>
                </React.Fragment>
              );
          })}
        </LogoWall_Grid>
      ))}
    </Container>
  );
};

const Cards: React.FC<any> = ({ blackBoxes }) => {
  const isMobile = useIsMobile();
  if (isMobile)
    return (
      <Container>
        {blackBoxes.map((v, k, self) => (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(12, 1fr)`,
              gridAutoColumns: `auto`,
              gridGap: "0px 24px",
              marginBottom: k !== self.length - 1 ? 80 : undefined,
            }}
          >
            <div
              key={k}
              style={{
                display: "flex",
                gridColumn: k % 2 === 0 ? "1 / 12" : "2 / 13",
              }}
            >
              <BlackBox
                large
                title={v.title}
                description={v.description}
                link={v.link}
                linkText={v.linkText}
              />
            </div>
          </div>
        ))}
      </Container>
    );
  return (
    <Container>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fit, minmax(270px, 1fr))`,
          gridGap: "24px 24px",
        }}
      >
        {blackBoxes.map((v, k) => (
          <div
            key={k}
            style={{
              display: "flex",
            }}
          >
            <BlackBox
              large
              title={v.title}
              description={v.description}
              link={v.link}
              linkText={v.linkText}
            />
          </div>
        ))}
      </div>
    </Container>
  );
};

const News: React.FC<{ links: any }> = ({ links }) => {
  const isMobile = useIsMobile();
  if (isMobile)
    return (
      <Container>
        <div
          style={{
            display: "grid",
            gridTemplate: `
". . ."
". a ."
". a ."
". . ."
". b b"
". b b"
"c c ."
"c c ."
`,
            gridTemplateColumns: `1fr 10fr 1fr`,
            gridGap: "24px 24px",
            marginBottom: 80,
          }}
        >
          <div
            style={{
              gridColumn: "1 / 5",
              gridRow: "1 / 5",
              zIndex: 0,
              backgroundSize: "cover",
              ...(typeof links[0].mainImage !== "undefined"
                ? {
                    backgroundImage: `url(${encodeURI(
                      links[0].mainImage.url,
                    )})`,
                  }
                : {}),
              ...(typeof links[0].heroImage !== "undefined"
                ? {
                    backgroundImage: `url(${encodeURI(
                      links[0].heroImage.url,
                    )})`,
                  }
                : {}),
            }}
          />
          {links.map((v, k) => (
            <BlackBox
              key={k}
              style={{
                zIndex: 1,
                gridArea: ["a", "b", "c"][k],
              }}
              title={v.title}
              description=""
              {...(typeof v.shortDescription !== "undefined"
                ? { description: v.shortDescription }
                : {})}
              {...(typeof v.smallExcerpt !== "undefined"
                ? { description: v.smallExcerpt }
                : {})}
              link={v}
              linkText={typeof v.smallExcerpt !== "undefined" ? "blog" : "news"}
            />
          ))}
        </div>
      </Container>
    );
  return (
    <Container>
      <div
        style={{
          display: "grid",
          gridTemplate: `
". . . . . . b b b"
". a a a a . b b b"
". a a a a . c c c"
". . . . . . c c c"
`,
          gridTemplateColumns: `repeat(9, 1fr)`,
          gridGap: "24px 24px",
          marginBottom: 80,
        }}
      >
        <div
          style={{
            gridColumn: "1 / 7",
            gridRow: "1 / 5",
            zIndex: 0,
            backgroundSize: "cover",
            ...(typeof links[0].mainImage !== "undefined"
              ? {
                  backgroundImage: `url(${encodeURI(links[0].mainImage.url)})`,
                }
              : {}),
            ...(typeof links[0].heroImage !== "undefined"
              ? {
                  backgroundImage: `url(${encodeURI(links[0].heroImage.url)})`,
                }
              : {}),
          }}
        />
        {links.map((v, k) => (
          <BlackBox
            key={k}
            style={{
              zIndex: 1,
              gridArea: ["a", "b", "c"][k],
            }}
            title={v.title}
            description=""
            {...(typeof v.shortDescription !== "undefined"
              ? { description: v.shortDescription }
              : {})}
            {...(typeof v.smallExcerpt !== "undefined"
              ? { description: v.smallExcerpt }
              : {})}
            link={v}
            linkText={typeof v.smallExcerpt !== "undefined" ? "blog" : "news"}
          />
        ))}
      </div>
    </Container>
  );
};

const TwoColumnMarkdownLeft = styled(ReactMarkdown)<{
  noBlackBoxOnLeft?: boolean;
  flipped?: boolean;
}>`
  background: #000;
  color: #fff;
  padding: 48px;
  grid-column: 1 / 5;
  ${({ flipped }) =>
    flipped
      ? css`
          grid-column: 9 / 13;
          grid-row: 1;
        `
      : ""}
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: 1.25em;
    hyphens: auto;
  }
  li,
  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
  }
  ul {
    padding: 0;
    list-style-position: inside;
    list-style: none;
    li:before {
      content: "- ";
    }
    li:not(:last-child) {
      margin-bottom: 0.75em;
    }
  }
  img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  }
  display: flex;
  align-items: center;
  ${({ noBlackBoxOnLeft }) =>
    noBlackBoxOnLeft &&
    css`
      background: none;
      padding: 0;
      color: #000;
    `}
`;
const TwoColumnMarkdownRight = styled(ReactMarkdown)<{ flipped?: boolean }>`
  grid-column: 6 / 13;
  ${({ flipped }) =>
    flipped
      ? css`
          grid-column: 1 / 8;
          grid-row: 1;
        `
      : ""}
  h4 {
    :first-child {
      margin-top: 18px;
    }
    font-size: 24px;
    font-weight: 700;
  }
  li,
  p {
    font-size: 24px;
    line-height: 36px;
  }
  img {
    width: 100%;
    max-width: 100%;
  }
  a {
    color: #00719c;
  }
`;
const TwoColumn_Base = styled.div<{
  noBlackBoxOnLeft?: boolean;
  flipped?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @media (max-width: 768px) {
    > ${TwoColumnMarkdownLeft} {
      grid-row: 1 / 2;
      grid-column: 1 / 11;
      ${({ flipped }) =>
        flipped
          ? css`
              grid-column: 3 / 13;
            `
          : ""}
    }
    > ${TwoColumnMarkdownRight} {
      grid-row: 2 / 3;
      grid-column: 1 / 11;
      ${({ flipped }) =>
        flipped
          ? css`
              grid-column: 3 / 13;
            `
          : ""}
    }
  }
`;
const TwoColumn: React.FC<{
  left: string;
  right: string;
  noBlackBoxOnLeft?: boolean;
  flipped?: boolean;
}> = ({ left, right, noBlackBoxOnLeft, flipped }) => {
  return (
    <Container style={{ marginBottom: 120 }}>
      <TwoColumn_Base flipped={flipped}>
        {left !== "" && (
          <TwoColumnMarkdownLeft
            source={left}
            noBlackBoxOnLeft={noBlackBoxOnLeft}
            flipped={flipped}
          />
        )}
        <TwoColumnMarkdownRight source={right} flipped={flipped} />
      </TwoColumn_Base>
    </Container>
  );
};

const ImageHero: React.FC<any> = props => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${encodeURI(props.backgroundImage.url)})`,
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
        marginBottom: 80,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container style={{ width: "100%", boxSizing: "border-box" }}>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gridGap: "0px 24px",
          }}
        >
          <div
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 13,
              gridRowStart: 1,
              gridRowEnd: 13,
            }}
          >
            <Link
              to="/"
              style={{
                display: "inline-block",
                color: "#fff",
              }}
              title="Framian"
            >
              <FramianLogo />
            </Link>
          </div>
        </div>
      </Container>
      <div
        style={{
          flex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Container style={{ width: "100%" }}>
          <div style={{ display: "inline-block", maxWidth: 480 }}>
            <BlackBox
              title={props.title}
              description={props.text}
              link={null}
              linkText={""}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

const ImageBlock: React.FC<any> = props => {
  return (
    <Container style={{}}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          margin: -20,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.images.map(v => (
          <div
            style={{
              minWidth: 300,
              maxWidth: 300,
              flex: "1",
              flexBasis: "auto",
              margin: 20,
            }}
          >
            {v.fluid !== null ? <Img {...v} /> : <img src={v.url} />}
          </div>
        ))}
      </div>
    </Container>
  );
};

const Image: React.FC<any> = props => {
  return (
    <Container style={{}}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          margin: -20,
          alignItems: "center",
          justifyContent: "center",
        }}
        css={`
          > img {
            max-width: 100%;
          }
        `}
      >
        {props.fluid !== null ? <Img {...props} /> : <img src={props.url} />}
      </div>
    </Container>
  );
};

const ContentWithSideBox_Left = styled(ReactMarkdown)<{
  invertColors?: boolean;
}>`
  color: ${({ invertColors }) => (invertColors ? "#fff" : "#000")};
  font-weight: 300;

  a {
    overflow: hidden;
    color: #000;
    box-shadow: inset 0px -2px 0px #000;
    :hover {
      color: #444;
      box-shadow: inset 0px -2px 0px #444;
    }
  }
  p {
    hyphens: auto;
  }
`;
const ContentWithSideBox_Box = styled(ReactMarkdown)<{
  invertColors?: boolean;
}>`
  color: ${({ invertColors }) => (invertColors ? "#000" : "#fff")};
  ul {
    list-style: none;
    padding: 0;
  }
  li:before {
    content: "+ ";
  }
  li:not(:last-child) {
    margin-bottom: 1em;
  }
  font-family: 'Roboto Mono', monospace;
  font-size: 16px
  line-height: 19px;
  font-weight: 300;
  a {
    overflow: hidden;
    color: ${({ invertColors }) => (invertColors ? "#000" : "#fff")};
    box-shadow: inset 0px -2px 0px rgba(255, 255, 255, 1);
    :hover {
      color: ${({ invertColors }) => (invertColors ? "#fff" : "#000")};
      box-shadow: inset 0px -2em 0px rgba(255, 255, 255, 1);
    }
    transition: color 200ms ease-out, box-shadow 200ms ease-out;
  }
`;

const ContentWithSideBox_Base = styled.div<{ invertColors?: boolean }>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 180px 0px;
  > div:nth-child(1) {
    > h3 {
      padding-top: 50px;
    }
    grid-column: 1 / 6;
    grid-row: 1 / 2;
  }
  > div:nth-child(2) {
    grid-column: 1 / 6;
    grid-row: 2 / 3;
    padding-bottom: 50px;
  }
  > div:nth-child(3) {
    grid-column: 7 / 13;
    grid-row: 1 / 2;
    background: ${({ invertColors }) => (invertColors ? "#fff" : "#000")};
  }
  > div:nth-child(4) {
    grid-column: 7 / 13;
    grid-row: 2 / 3;
    background: ${({ invertColors }) => (invertColors ? "#fff" : "#000")};
    padding: 90px;
    padding-top: 0;
  }
  @media (max-width: 768px) {
    padding: 80px 0px;
    > div:nth-child(1) {
      > h3 {
        padding-top: 0;
      }
      grid-column: 1 / 13;
      grid-row: 1 / 2;
    }
    > div:nth-child(2) {
      grid-column: 1 / 13;
      grid-row: 2 / 3;
      padding-bottom: 15px;
    }
    > div:nth-child(3) {
      display: none;
    }
    > div:nth-child(4) {
      > div > :first-child {
        margin-top: 0;
      }
      > div > :last-child {
        margin-bottom: 0;
      }
      padding: 45px;
      padding-top: 45px;
      grid-column: 1 / 13;
      grid-row: 3 / 4;
    }
  }
`;

const ContentWithSideBox: React.FC<any> = props => {
  return (
    <div style={{ background: props.invertColors ? "#000" : "#fff" }}>
      <Container>
        <ContentWithSideBox_Base invertColors={props.invertColors}>
          <div>
            <h3
              style={{
                margin: 0,
                color: props.invertColors ? "#fff" : "#000",
                hyphens: "auto",
              }}
            >
              {props.title}
            </h3>
          </div>
          <div>
            <ContentWithSideBox_Left
              source={props.leftContent}
              invertColors={props.invertColors}
            />
          </div>
          <div></div>
          <div style={{}}>
            <ContentWithSideBox_Box
              source={props.boxContent}
              invertColors={props.invertColors}
            />
          </div>
        </ContentWithSideBox_Base>
      </Container>
    </div>
  );
};

const ContentWithSideBox2_Base = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  > div:nth-child(1) {
    > h3 {
      padding-top: 50px;
    }
    grid-column: 1 / 7;
    grid-row: 1 / 2;
  }
  > div:nth-child(2) {
    grid-column: 1 / 7;
    grid-row: 2 / 3;
    padding-bottom: 50px;
  }
  > div:nth-child(3) {
    grid-column: 7 / 13;
    grid-row: 1 / 2;
    background: #000;
  }
  > div:nth-child(4) {
    grid-column: 7 / 13;
    grid-row: 2 / 3;
    background: #000;
    margin-top: 18px;
  }
  @media (max-width: 768px) {
    > div:nth-child(1) {
      > h3 {
        padding-top: 0;
      }
      grid-column: 1 / 13;
      grid-row: 1 / 2;
    }
    > div:nth-child(2) {
      grid-column: 1 / 13;
      grid-row: 2 / 3;
      padding-bottom: 15px;
    }
    > div:nth-child(3) {
      display: none;
    }
    > div:nth-child(4) {
      > div > :first-child {
        margin-top: 0;
      }
      > div > :last-child {
        margin-bottom: 0;
      }
      grid-column: 1 / 13;
      grid-row: 3 / 4;
    }
  }
`;
export const ContentWithSideBox2: React.FC<any> = props => {
  const lang = React.useContext(SiteLanguageContext);
  return (
    <div style={{ background: "#000" }}>
      <Container>
        <ContentWithSideBox2_Base>
          <div style={{ color: "#fff" }}>
            <h3
              style={{
                margin: 0,
              }}
            >
              {lang === "en" && <>Book an online meet up</>}
              {lang === "fi" && <>Varaa online-tapaaminen</>}
            </h3>
          </div>
          <div>
            <p style={{ color: "#fff" }}>
              &#128337; 30 min
              <br />
              &#128197; {lang === "en" && <>Monday - Friday</>}
              {lang === "fi" && <>maanantain - perjantai</>}
              <br />
              <br />
              {lang === "en" && (
                <>
                  We are more than willing to answer any questions you may have.
                  Book a 30 minute online meet up by selecting a suitable time
                  for you. Fill out your email and get the web conferencing
                  details.
                </>
              )}
              {lang === "fi" && (
                <>
                  Haluatko kuulla lisää palveluistamme? Valitse sinulle sopiva
                  ajankohta ja varaa tapaaminen. Täytä oheiseen lomakkeeseen
                  tietosi, niin lähetämme sinulle linkin tapaamisen
                  sähköpostilla.
                </>
              )}
            </p>
          </div>
          <div></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <VarausFormi />
          </div>
        </ContentWithSideBox2_Base>
      </Container>
    </div>
  );
};

const TextHero_Background_Animation = styled.div`
  animation: ${keyframes`
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 100000px 100000px;
  }
  `} 1s linear infinite;
`;
const TextHero_Background: React.FC = () => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [tick, setTick] = React.useState(0);
  React.useEffect(() => {
    const cb = () => {
      setTick(tick => tick + 1);
    };
    window.addEventListener("resize", cb, false);
    return () => window.removeEventListener("resize", cb, false);
  }, [setTick]);
  React.useEffect(() => {
    const canvas = document.createElement("canvas");
    canvas.width = 512;
    canvas.height = 512;
    const ctx = canvas.getContext("2d");
    for (let j = 0; j < canvas.width; j += 1)
      for (let i = 0; i < canvas.height; i += 1) {
        ctx.fillStyle = "#000";
        if (Math.random() < 0.5) ctx.fillStyle = "#111";
        ctx.fillRect(j, i, 1, 1);
      }
    divRef.current!.style["background"] = `url(${encodeURI(
      canvas.toDataURL("image/png"),
    )}) repeat`;
  }, [tick]);
  return (
    <TextHero_Background_Animation
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        background: "#090909",
        overflow: "hidden",
      }}
      ref={divRef}
    />
  );
};
const TextHero: React.FC<any> = ({ title, text }) => {
  return (
    <div style={{ background: "#000", position: "relative" }}>
      <TextHero_Background />
      <Container
        style={{
          position: "relative",
          zIndex: 2,
          marginBottom: -52,
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gridGap: "0px 24px",
          }}
        >
          <div
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 13,
              gridRowStart: 1,
              gridRowEnd: 13,
            }}
          >
            <Link
              to="/"
              style={{
                display: "inline-block",
                color: "#fff",
              }}
              title="Framian"
            >
              <FramianLogo />
            </Link>
          </div>
        </div>
      </Container>
      <Container
        style={{
          position: "relative",
          zIndex: 1,
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", maxWidth: 640 }}>
          <Heading title={title} invert />
          <p
            style={{
              color: "#fff",
              marginTop: -40,
              marginBottom: -40,
              whiteSpace: "pre-wrap",
            }}
          >
            {text}
          </p>
          <Heading invert />
        </div>
      </Container>
    </div>
  );
};

const Faq = ({ qA }) => {
  const faq = qA
    .split(/--/)
    .map(v => v.trim())
    .map(v => {
      let [q, ...rest] = v.split(/\n/);
      rest = rest.filter(v => v !== "");
      return {
        q,
        a: () => (
          <>
            {rest.map(v => (
              <p>{v}</p>
            ))}
          </>
        ),
      };
    });
  const [opened, setOpened] = React.useState(() => []);
  return (
    <Container>
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gridGap: "0px 24px",
        }}
      >
        <div
          css={`
            grid-column: 3 / 13;
            margin-left: -20px;
            @media (max-width: 768px) {
              grid-column: 1 / 13;
              margin-left: auto;
              label {
                font-size: 20px;
              }
            }
            label {
              hyphens: auto;
              overflow: hidden;
            }
          `}
        >
          {opened.map(v => (
            <Trigger key={v} name={`faq ${v}`} />
          ))}
          <div>
            <div
              css={`
                display: grid;
                grid-gap: 5px;
              `}
            >
              {faq.map((v, k) => (
                <label
                  key={k}
                  css={`
                    cursor: pointer;
                    background: #fff;
                    font-family: "Roboto", sans-serif;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 1.56;
                    .closed {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      padding: 20px;
                      svg {
                        margin-right: 30px;
                      }
                    }
                    .open {
                      > div:nth-child(1) {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 20px;
                        svg {
                          margin-right: 30px;
                        }
                      }
                      > div:nth-child(2) {
                        padding: 20px;
                        padding-left: 80px;
                        padding-top: 0;
                      }
                    }
                    .open {
                      display: none;
                    }
                    input[type="checkbox"]:checked ~ .closed {
                      display: none;
                    }
                    input[type="checkbox"]:checked ~ .open {
                      display: block;
                    }
                    color: #000;
                    user-select: none;
                  `}
                  onClick={e => {
                    if (opened.indexOf(v.q) !== -1) return;
                    setOpened([...opened, v.q]);
                    if (typeof (window as any).fbq !== "undefined")
                      (window as any).fbq("trackCustom", "ViewFAQ", {
                        question: v.q,
                      });
                  }}
                >
                  <input type="checkbox" style={{ display: "none" }} />
                  <div className="closed">
                    <div>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25 14H16V5H14V14H5V16H14V25H16V16H25V14Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div>{v.q}</div>
                  </div>
                  <div className="open">
                    <div>
                      <div>
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M25 16V14L5 14V16L25 16Z" fill="black" />
                        </svg>
                      </div>
                      <div>{v.q}</div>
                    </div>
                    <div
                      css={`
                        p {
                           margin: 0;
                        }
                        display: grid;
                        grid-gap: 10px;
                      `}
                    >
                      <v.a />
                    </div>
                  </div>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const App: React.FC<{ data: any; path: string }> = ({ data, path }) => {
  const homePath =
    ["/ideasta-tuote/", "/headless/"].indexOf(path) !== -1
      ? "/fi/"
      : path.startsWith("/fi/")
      ? "/fi/"
      : "/";
  const heroData = data.datoCmsPage.block.find(
    (v: any) => v.model?.apiKey === "hero",
  );
  const hasHero =
    typeof data.datoCmsPage.block.find(
      (v: any) =>
        ["hero", "text_hero", "image_hero"].indexOf(v.model?.apiKey) !== -1,
    ) !== "undefined";
  return (
    <>
      {typeof heroData !== "undefined" && (
        <Hero description={heroData.description} homePath={homePath} />
      )}
      {!hasHero && (
        <div style={{ background: "#fff", marginBottom: -52 }}>
          <Container style={{ width: "100%", boxSizing: "border-box" }}>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(12, 1fr)",
                gridGap: "0px 24px",
              }}
            >
              <div
                style={{
                  gridColumnStart: 1,
                  gridColumnEnd: 13,
                  gridRowStart: 1,
                  gridRowEnd: 13,
                }}
              >
                <Link
                  to={homePath}
                  style={{
                    display: "inline-block",
                    color: "#000",
                  }}
                  title="Framian"
                >
                  <FramianLogo />
                </Link>
              </div>
            </div>
          </Container>
        </div>
      )}
      <div style={{ background: "#ffffff" }}>
        {data.datoCmsPage.block.map((v, k) => {
          if (v === null) return null;
          const apiKey = v.model?.apiKey;
          if (apiKey === "description_with_link")
            return (
              <Intro
                key={v.id}
                text={v.text}
                link={v.link}
                linkText={v.linkText}
              />
            );
          if (apiKey === "heading")
            return <Heading key={v.id} title={v.title} />;
          if (apiKey === "reference")
            return (
              <React.Fragment key={v.id}>
                <RefBlock {...v} />
                <Testimonial
                  quoteText={v.quoteText}
                  titleOnTheRight={v.titleOnTheRight}
                  quotePerson={v.quotePerson}
                />
              </React.Fragment>
            );
          if (apiKey === "box_row") return <Cards key={v.id} {...v} />;
          if (apiKey === "logo_wall") return <LogoWall key={v.id} {...v} />;
          if (apiKey === "news") return <News key={v.id} {...v} />;
          if (apiKey === "two_column") return <TwoColumn {...v} />;
          if (apiKey === "spacer")
            return <div style={{ paddingBottom: 100 }} />;
          if (apiKey === "image_hero") return <ImageHero {...v} />;
          if (apiKey === "image_block") return <ImageBlock {...v} />;
          if (apiKey === "single_image") return <Image {...v.image} />;
          if (apiKey === "content_with_side_box")
            return <ContentWithSideBox {...v} />;
          if (apiKey === "text_hero") return <TextHero {...v} />;
          if (apiKey === "faq") return <Faq {...v} />;
          return null;
        })}
      </div>
      <Footer path={path} />
    </>
  );
};

export default ({
  data,
  location: { pathname: path },
}: {
  data: any;
  pageContext: any;
  rest: any;
  path: string;
  location: { pathname: string };
}) => {
  const allPaths = useSitemap();
  const lang = path.startsWith("/fi/") ? "fi" : "en";
  return (
    <>
      <Trigger name={`pageview ${path}`} />
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://www.datocms-assets.com" />
      </Helmet>
      <SEO
        path={path}
        lang={lang}
        title={data.datoCmsPage.title}
        metaDescription={data.datoCmsPage.shortDescription}
      />
      <Helmet>
        {data.datoCmsPage.seoMetaTags.tags.map(
          ({ tagName, content, attributes }, k) =>
            React.createElement(tagName, { key: k, ...attributes }, content),
        )}
        <link rel="canonical" href={`https://framian.fi${path}`} />
        {["en", "fi", "x-default"].map(hrefLang => {
          const locale = hrefLang === "x-default" ? "en" : hrefLang;
          const page = allPaths.find((v: any) => v.id === data.datoCmsPage.id);
          if (typeof page === "undefined") return null;
          const { id } = page;
          const alt = allPaths.find(
            v => v.id === `${id.replace(/-[a-z]+$/, "")}-${locale}`,
          );
          if (typeof alt === "undefined") return null;
          return (
            <link
              rel="alternate"
              hrefLang={hrefLang}
              href={`https://framian.fi${alt.path}`}
            />
          );
        })}
      </Helmet>
      <SiteLanguageContext.Provider value={lang}>
        <SitemapContext.Provider value={allPaths}>
          <App data={data} path={path} />
          <Menu path={path} />
        </SitemapContext.Provider>
        <CookiePopup path={path} />
      </SiteLanguageContext.Provider>
      <GlobalStyles />
      <Favicon />
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      id
      title
      shortDescription
      seoMetaTags {
        tags
      }
      block {
        ... on DatoCmsHero {
          id
          model {
            apiKey
          }
          description
        }
        ... on DatoCmsHeading {
          id
          model {
            apiKey
          }
          title
        }
        ... on DatoCmsDescriptionWithLink {
          id
          model {
            apiKey
          }
          text
          link {
            id
          }
          linkText
        }
        ... on DatoCmsReference {
          id
          model {
            apiKey
          }
          quoteText
          quotePerson {
            personImage {
              title
              alt
              fluid(maxWidth: 72, imgixParams: { bg: "#c4c4c4" }) {
                ...GatsbyDatoCmsFluid_tracedSVG
              }
            }
            personName
            personTitleCompany
          }
          titleOnTheRight
          title
          description
          linkText
          link {
            id
          }
          image {
            title
            alt
            fluid(maxWidth: 976, imgixParams: { bg: "#c4c4c4" }) {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
          }
        }
        ... on DatoCmsBoxRow {
          id
          model {
            apiKey
          }
          blackBoxes {
            title
            description
            linkText
            link {
              ... on DatoCmsPage {
                id
              }
              ... on DatoCmsExternalLink {
                link
              }
            }
          }
        }
        ... on DatoCmsLogoWall {
          id
          model {
            apiKey
          }
          logoBoxes {
            title
            description
            logo {
              title
              alt
              fluid(maxWidth: 176, imgixParams: { bg: "#ffffff" }) {
                ...GatsbyDatoCmsFluid_tracedSVG
              }
              url
            }
          }
        }
        ... on DatoCmsNews {
          id
          model {
            apiKey
          }
          links {
            ... on DatoCmsPage {
              id
              title
              shortDescription
              mainImage {
                title
                alt
                url
              }
            }
            ... on DatoCmsBlog {
              id
              title
              smallExcerpt
              heroImage {
                title
                alt
                url
              }
            }
          }
        }
        ... on DatoCmsTwoColumn {
          id
          model {
            apiKey
          }
          left
          right
          noBlackBoxOnLeft
          flipped
        }
        ... on DatoCmsSpacer {
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsImageHero {
          id
          model {
            apiKey
          }
          title
          text
          backgroundImage {
            title
            alt
            url
            fluid(imgixParams: { bg: "#ffffff" }) {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
          }
        }
        ... on DatoCmsImageBlock {
          id
          model {
            apiKey
          }
          images: image {
            title
            alt
            url
            fluid(imgixParams: { bg: "#ffffff" }) {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
          }
        }
        ... on DatoCmsSingleImage {
          id
          model {
            apiKey
          }
          image {
            title
            alt
            url
            fluid(imgixParams: { bg: "#ffffff" }) {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
          }
        }
        ... on DatoCmsContentWithSideBox {
          id
          model {
            apiKey
          }
          title
          leftContent
          boxContent
          invertColors
        }
        ... on DatoCmsTextHero {
          id
          model {
            apiKey
          }
          title
          text
        }
        ... on DatoCmsFaq {
          id
          model {
            apiKey
          }
          qA
        }
      }
    }
  }
`;

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Favicon = () => {
  const data = useStaticQuery(graphql`
    {
      lightSVG: file(base: { eq: "Framian-favicon.svg" }) {
        publicURL
      }
      lightPNG: file(base: { eq: "Framian-favicon.png" }) {
        publicURL
      }
      darkSVG: file(base: { eq: "Framian-favicon-dark.svg" }) {
        publicURL
      }
      darkPNG: file(base: { eq: "Framian-favicon-dark.png" }) {
        publicURL
      }
    }
  `);
  const [darkMode, setDarkMode] = React.useState(false);
  React.useEffect(() => {
    var mqDark = window.matchMedia("(prefers-color-scheme: dark)");
    function cb(event) {
      setDarkMode(event.matches);
    }
    try {
      mqDark.addEventListener("change", cb);
    } catch (maybeSafari) {
      try {
        mqDark.addListener(cb);
      } catch (err) {}
    }
    setDarkMode(mqDark.matches);
  }, []);
  return (
    <Helmet>
      <link
        rel="mask-icon"
        href={data.lightSVG.publicURL}
        color={darkMode ? "#fff" : "#000"}
      />
      <link
        rel="alternate icon"
        type="image/png"
        href={darkMode ? data.darkPNG.publicURL : data.lightPNG.publicURL}
      />
      <link
        rel="icon"
        type="image/svg+xml"
        href={darkMode ? data.darkSVG.publicURL : data.lightSVG.publicURL}
      />
    </Helmet>
  );
};

export default Favicon;

import React from "react";
import moment from "moment";
import "moment-timezone";
import styled from "styled-components";
import { SiteLanguageContext } from "../templates/black";

moment.tz.setDefault("Europe/Helsinki");
moment.locale("en", { week: { dow: 1 } });

const useData = tick => {
  const [state, setState] = React.useState(undefined);
  const url =
    "https://nglumxz6ke.execute-api.eu-central-1.amazonaws.com/default/googleapis-calendar-bot";
  React.useEffect(() => {
    let cancel = false;
    (async () => {
      const res = await fetch(url).then(v => v.json());
      if (cancel) return;
      setState(res);
    })();
    return () => {
      cancel = true;
    };
  }, [tick]);
  const sub = React.useCallback(data => {
    return new Promise(resolve => {
      (async () => {
        await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then(v => v.json());
        resolve();
      })();
    });
  }, []);
  return [state, sub];
};

const Input = styled.input`
  border: 2px solid #fff;
  :focus {
    border: 2px solid #000;
  }
`;

const Button = styled.button`
  border: 2px solid #fff;
  :active {
    border: 2px solid #000;
  }
  :disabled {
    opacity: 0.75;
  }
`;

const App = () => {
  const lang = React.useContext(SiteLanguageContext);
  const [tick, setTick] = React.useState(0);
  const [data, sub] = useData(tick);
  const [week, setWeek] = React.useState(() => moment().startOf("week"));
  const [selected, setSelected] = React.useState(undefined);
  const slots = React.useMemo(
    () => selected => {
      if (typeof data === "undefined") return [];
      const now = moment().add(24, "hour");
      const slots = [
        "09:00-09:30",
        "09:30-10:00",
        "10:00-10:30",
        "10:30-11:00",
      ];
      const out = [];
      for (let j = 0; j < slots.length; j += 1) {
        const slot = slots[j];
        const m = slot
          .match(/^([0-9]+):([0-9]+)-([0-9]+):([0-9]+)$/)
          .slice(1)
          .map(v => parseInt(v, 10));
        const start = moment(selected).set({
          hour: m[0],
          minute: m[1],
        });
        const end = moment(selected).set({ hour: m[2], minute: m[3] });
        let conflict = false;
        data.forEach(v => {
          if (
            moment(v.start.dateTime).isSame(start) &&
            moment(v.end.dateTime).isSame(end)
          ) {
            conflict = true;
          }
        });
        if ([5, 6].indexOf(start.get("weekday")) !== -1) continue;
        if (now.isAfter(start)) continue;
        if (conflict) continue;
        out.push({
          start,
          end,
        });
      }
      return out;
    },
    [data],
  );
  const hours = React.useMemo(() => {
    return slots(selected);
  }, [selected, slots]);
  const [selectedSlot, setSelectedSlot] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [thanks, setThanks] = React.useState(false);
  React.useEffect(() => {
    if (typeof data === "undefined") return;
    for (let i = 0; i < 28; i += 1) {
      const d = moment().add(i, "day").startOf("day");
      if (slots(d).length > 0) {
        setWeek(moment(d).startOf("week"));
        setSelected(d);
        return () => {};
      }
    }
    return () => {};
  }, [data, slots]);
  if (typeof data === "undefined") return null;
  let now = moment().startOf("week");
  const days = [];
  for (let i = 0; i < 14; i += 1) {
    const slots = ["09:00-09:30", "09:30-10:00", "10:00-10:30", "10:30-11:00"];
    const day = {
      name: now.format("dddd"),
      name2: now.format("D.M."),
      slots: [],
    };
    for (let j = 0; j < slots.length; j += 1) {
      const slot = slots[j];
      const m = slot
        .match(/^([0-9]+):([0-9]+)-([0-9]+):([0-9]+)$/)
        .slice(1)
        .map(v => parseInt(v, 10));
      const start = moment(now).set({ hour: m[0], minute: m[1] });
      const end = moment(now).set({ hour: m[2], minute: m[3] });
      let conflict = false;
      data.forEach(v => {
        if (
          moment(v.start.dateTime).isSame(start) &&
          moment(v.end.dateTime).isSame(end)
        ) {
          conflict = true;
        }
      });
      if ([5, 6].indexOf(start.get("weekday")) !== -1) continue;
      day.slots.push({
        active: moment().isBefore(start) && !conflict,
        start,
        end,
      });
    }
    days.push(day);
    now = now.add(1, "day").startOf("day");
  }
  const weekSpec = `${week.locale(lang).format("MMM D")} - ${moment(week)
    .add(7, "day")
    .startOf("week")
    .subtract(1, "second")
    .locale(lang)
    .format("MMM D")}`;
  return (
    <>
      <div
        style={{
          display: "inline-grid",
          background: "#fff",
          color: "#000",
          gridTemplate: `
    "a a a a a a a"
    "h1 h2 h3 h4 h5 h6 h7"
    "v1 v2 v3 v4 v5 v6 v7"
    "b b b b b b b"
    "c c c c c c c"
    "d d d d d d d"
    `,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gridArea: "a",
            margin: 4,
          }}
        >
          <button
            onClick={e => {
              e.preventDefault();
              if (thanks) return;
              setWeek(moment().startOf("week"));
              setSelected(undefined);
              setSelectedSlot(undefined);
              setLoading(false);
              setThanks(false);
            }}
            style={{
              border: "2px solid #000",
              padding: "6px 8px",
              fontFamily: "monospace",
              cursor: "pointer",
              outline: "none",
            }}
          >
            {lang === "en" && <>Today</>}
            {lang === "fi" && <>Tänään</>}
          </button>
          <p
            style={{
              padding: "2px 8px",
              fontFamily: "monospace",
              margin: 0,
              marginTop: -2,
              fontSize: 10,
            }}
          >
            {weekSpec}
          </p>
          <button
            onClick={e => {
              e.preventDefault();
              if (thanks) return;
              setWeek(moment(week).add(1, "week").startOf("week"));
              setSelected(undefined);
              setSelectedSlot(undefined);
              setLoading(false);
              setThanks(false);
            }}
            style={{
              border: "2px solid #000",
              padding: "6px 8px",
              fontFamily: "monospace",
              cursor: "pointer",
              outline: "none",
            }}
          >
            {lang === "en" && <>+1 week</>}
            {lang === "fi" && <>+1 viikko</>}
          </button>
        </div>
        {Array.from({ length: 7 }).map((_, k) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 10,
              background: "#eee",
              borderTop: "1px solid #000",
              fontFamily: "monospace",
              padding: 4,
            }}
          >
            {moment(week)
              .add(k, "days")
              .startOf("day")
              .locale(lang)
              .format("ddd")}
          </div>
        ))}
        {Array.from({ length: 7 }).map((_, k) => {
          const d = moment(week).add(k, "days").startOf("day");
          const hasSlots = slots(d).length > 0;
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#eee",
                borderTop: "1px solid #000",
              }}
            >
              {hasSlots && (
                /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                <a
                  href=""
                  style={{
                    borderRadius: 8,
                    background: hasSlots ? "#fff" : undefined,
                    color: "#000",
                    padding: 3,
                    margin: 4,
                    fontFamily: "monospace",
                    textDecoration: "none",
                    border: !hasSlots
                      ? "2px solid transparent"
                      : typeof selected !== "undefined" && selected.isSame(d)
                      ? "2px solid #000"
                      : "2px solid #fff",
                    whiteSpace: "pre",
                    fontWeight: moment()
                      .startOf("day")
                      .isSame(moment(week).add(k, "days").startOf("day"))
                      ? "bold"
                      : undefined,
                  }}
                  onClick={e => {
                    e.preventDefault();
                    if (thanks) return;
                    setSelected(d);
                    setSelectedSlot(undefined);
                    setLoading(false);
                    setThanks(false);
                  }}
                >
                  {(" " + d.format("D")).substr(-2)}
                </a>
              )}
              {!hasSlots && (
                <span
                  style={{
                    borderRadius: 8,
                    color: "#000",
                    padding: 3,
                    margin: 4,
                    fontFamily: "monospace",
                    textDecoration: "none",
                    border: "2px solid transparent",
                    whiteSpace: "pre",
                    fontWeight: moment()
                      .startOf("day")
                      .isSame(moment(week).add(k, "days").startOf("day"))
                      ? "bold"
                      : undefined,
                  }}
                >
                  {(" " + d.format("D")).substr(-2)}
                </span>
              )}
            </div>
          );
        })}
        {selected && (
          <div
            style={{
              gridArea: "b",
              background: "#eee",
              borderTop: "1px solid #000",
              padding: 4,
            }}
          >
            {(() => {
              return hours.map((v, k, self) => (
                <div
                  style={{
                    marginBottom: k !== self.length - 1 ? 4 : undefined,
                  }}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href=""
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      background: "#fff",
                      display: "inline-block",
                      padding: "6px 8px",
                      borderRadius: 8,
                      fontFamily: "monospace",
                      border:
                        selectedSlot === v
                          ? "2px solid #000"
                          : "2px solid #fff",
                    }}
                    onClick={e => {
                      e.preventDefault();
                      if (thanks) return;
                      setSelectedSlot(v);
                    }}
                  >
                    {`${v.start.format("HH:mm")} - ${v.end.format("HH:mm")}`}
                  </a>
                </div>
              ));
            })()}
          </div>
        )}
        {typeof selectedSlot !== "undefined" && (
          <form
            style={{
              gridArea: "c",
              background: "#eee",
              borderTop: "1px solid #000",
              padding: 4,
              margin: 0,
            }}
            onSubmit={e => {
              e.preventDefault();
              const fd = new FormData(e.target);
              setLoading(true);
              sub({
                email: fd.get("email"),
                start: selectedSlot.start.toISOString(),
                end: selectedSlot.end.toISOString(),
              }).then(v => {
                setLoading(false);
                setThanks(true);
              });
            }}
          >
            <p
              style={{
                fontFamily: "monospace",
                margin: 0,
                marginTop: "0.5em",
                marginBottom: "0.5em",
                fontSize: 14,
                textAlign: "center",
              }}
            >
              {selectedSlot.start.locale(lang).format("dddd D.M.YYYY HH:mm")}
            </p>
            <label htmlFor="email">
              <span
                style={{
                  fontFamily: "monospace",
                  fontSize: 10,
                }}
              >
                {lang === "en" && <>Email:</>}
                {lang === "fi" && <>Sähköpostiosoite:</>}
              </span>
              <Input
                id="email"
                /* eslint-disable-next-line jsx-a11y/no-autofocus */
                autoFocus
                style={{
                  fontFamily: "monospace",
                  background: "#fff",
                  borderRadius: 8,
                  padding: "6px 8px",
                  outline: "none",
                  width: "100%",
                  display: "block",
                  boxSizing: "border-box",
                  marginBottom: 4,
                }}
                name="email"
                type="email"
                disabled={loading || thanks}
                required
              />
            </label>
            <Button
              disabled={loading || thanks}
              style={{
                fontFamily: "monospace",
                background: "#fff",
                borderRadius: 8,
                padding: "6px 8px",
                outline: "none",
                cursor: "pointer",
              }}
            >
              {lang === "en" && <>Submit</>}
              {lang === "fi" && <>Lähetä</>}
            </Button>
          </form>
        )}
        {thanks && (
          <div
            style={{
              gridArea: "d",
              background: "#eee",
              borderTop: "1px solid #000",
              padding: 4,
            }}
          >
            <p
              style={{
                margin: 0,
                marginBottom: 6,
                fontFamily: "monospace",
                fontSize: 14,
                lineHeight: "auto",
              }}
            >
              {lang === "en" && (
                <>
                  <strong>Thanks!</strong>
                  <br />
                  Check your email for
                  <br />
                  the meeting details!
                </>
              )}
              {lang === "fi" && (
                <>
                  <strong>Kiitos!</strong>
                  <br />
                  Katso sähköpostiviestistä
                  <br />
                  tapaamisen tiedot!
                </>
              )}
            </p>
            <button
              style={{
                fontFamily: "monospace",
                background: "#fff",
                borderRadius: 8,
                padding: "6px 8px",
                outline: "none",
                cursor: "pointer",
              }}
              onClick={e => {
                e.preventDefault();
                setWeek(moment().startOf("week"));
                setSelected(undefined);
                setSelectedSlot(undefined);
                setLoading(false);
                setThanks(false);
                setTick(tick => tick + 1);
              }}
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
            >
              {lang === "en" && <>Close</>}
              {lang === "fi" && <>Suljet</>}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default App;

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO: React.FC<{
  path: string;
  lang: string;
  title: string;
  metaDescription: string;
}> = ({ path, lang, title, metaDescription }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsSite {
        nodes {
          locale
          globalSeo {
            siteName
            fallbackSeo {
              description
              image {
                url
                width
                height
                mimeType
              }
            }
          }
        }
      }
      flogo: file(base: { eq: "1589789731-f-logo.png" }) {
        publicURL
      }
    }
  `);
  const { globalSeo } = data.allDatoCmsSite.nodes.find(
    ({ locale }) => locale === lang,
  );
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={
          ["Index", "Etusivu"].indexOf(title) === -1
            ? title
            : globalSeo.siteName
        }
        titleTemplate={
          ["Index", "Etusivu"].indexOf(title) === -1
            ? `%s | ${globalSeo.siteName}`
            : undefined
        }
        meta={[
          {
            name: `description`,
            content: metaDescription || globalSeo.fallbackSeo.description,
          },
          {
            property: `og:title`,
            content: title !== "Index" ? title : globalSeo.siteName,
          },
          {
            property: `og:site_name`,
            content: globalSeo.siteName,
          },
          {
            property: `og:image`,
            content: globalSeo.fallbackSeo.image.url,
          },
          {
            property: `og:image:type`,
            content: globalSeo.fallbackSeo.image.mimeType,
          },
          {
            property: `og:image:width`,
            content: globalSeo.fallbackSeo.image.width,
          },
          {
            property: `og:image:height`,
            content: globalSeo.fallbackSeo.image.height,
          },
          {
            property: `og:description`,
            content: metaDescription || globalSeo.fallbackSeo.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:url`,
            content: `https://framian.fi${path}`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:site`,
            content: "@FramianFi",
          },
          {
            name: `twitter:creator`,
            content: "@FramianFi",
          },
          {
            name: `twitter:title`,
            content: title !== "Index" ? title : globalSeo.siteName,
          },
          {
            name: `twitter:description`,
            content: metaDescription || globalSeo.fallbackSeo.description,
          },
          {
            name: `twitter:image`,
            content: globalSeo.fallbackSeo.image.url,
          },
        ]}
      />
      <Helmet>
        <link rel="canonical" href={`https://framian.fi${path}`} />
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Corporation",
              name: "Framian Oy",
              alternateName: "Framian Ltd",
              url: "https://framian.fi/",
              logo: `https://framian.fi${data["flogo"].publicURL}`,
              sameAs: [
                "https://www.facebook.com/framian",
                "https://twitter.com/FramianFi",
                "https://www.instagram.com/framian",
                "https://www.youtube.com/channel/UCgN_QOLMMGB7l4Tq2DkZe2A",
                "https://www.linkedin.com/company/framian",
              ],
            },
            null,
            2,
          )}
        </script>
      </Helmet>
    </>
  );
};

export default SEO;

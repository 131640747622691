import React from "react";

const Trigger: React.FC<{ name: string }> = ({ name }) => {
  const url =
    "https://oz9bpuaem3.execute-api.eu-central-1.amazonaws.com/default/framianfi-visitor-tracker";
  React.useEffect(() => {
    if (window.location.origin !== "https://framian.fi") return;
    let cancel = false;
    (async () => {
      let parentTs = undefined;
      try {
        const { ts, expire } = JSON.parse(localStorage.getItem("ts"));
        if (Date.now() > expire) throw new Error("skip");
        parentTs = ts;
      } catch (err) {}
      const { ts } = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: name,
          ...(typeof parentTs !== "undefined" ? { ts: parentTs } : {}),
        }),
      }).then(v => v.json());
      if (cancel) return;
      if (typeof ts !== "undefined") {
        try {
          localStorage.setItem(
            "ts",
            JSON.stringify({ ts, expire: Date.now() + 60 * 60 * 24 * 1000 }),
          );
        } catch (err) {}
      }
    })();
    return () => {
      cancel = true;
    };
  }, [name]);

  return null;
};

export default Trigger;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const useSitemap = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsPage(filter: { root: { eq: true } }) {
        edges {
          node {
            id
            published
            slug
            locale
            title
            position
            block {
              __typename
            }
            treeChildren {
              id
              published
              slug
              locale
              title
              position
              block {
                __typename
              }
              treeChildren {
                id
                published
                slug
                locale
                title
                position
                block {
                  __typename
                }
              }
            }
          }
        }
      }
      allDatoCmsBlog {
        edges {
          node {
            id
            slug
            title
            locale
            showInBlogIndex
            showInEnBlogIndex
          }
        }
      }
    }
  `);

  const PATH_PREFIX = "/";
  const DEFAULT_LOCALE = "en";

  const flatten = arr => Array.prototype.concat.apply([], arr);

  const allPathsTravel = (node, previousSlugs = []) => {
    const { title, id, published, slug, position, treeChildren, locale } = node;
    if (node.block.length === 0)
      return flatten(
        treeChildren.map(node =>
          allPathsTravel(node, [...previousSlugs, slug]),
        ),
      );
    return [
      {
        id,
        published,
        title,
        position,
        locale,
        path:
          PATH_PREFIX +
          [
            ...(locale !== DEFAULT_LOCALE ? [locale] : []),
            ...previousSlugs,
            ...(slug !== "" && slug !== null ? [slug] : []),
            "",
          ].join("/"),
      },
      ...flatten(
        treeChildren.map(node =>
          allPathsTravel(node, [...previousSlugs, slug]),
        ),
      ),
    ];
  };
  let allPaths = flatten(
    data.allDatoCmsPage.edges.map(edge => allPathsTravel(edge.node)),
  )
    .sort((a, b) => {
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    })
    .map(({ position, ...rest }) => rest)
    .map(({ ...rest }) => rest);

  allPaths = [
    ...allPaths,
    ...data.allDatoCmsBlog.edges
      .filter(({ node: { showInBlogIndex } }) => showInBlogIndex)
      .map(edge => ({
        id: edge.node.id,
        path: `/fi/blogi/${edge.node.slug}/`,
        title: edge.node.title,
        locale: edge.node.locale,
        published: true,
      })),
  ];

  allPaths = [
    ...allPaths,
    ...data.allDatoCmsBlog.edges
      .filter(({ node: { showInEnBlogIndex } }) => showInEnBlogIndex)
      .map(edge => ({
        id: edge.node.id,
        path: `/blog/${edge.node.slug}/`,
        title: edge.node.title,
        locale: edge.node.locale,
        published: true,
      })),
  ];

  allPaths = [
    ...allPaths,
    {
      id: "ideastatuote-fi",
      path: "/ideasta-tuote/",
      title: "Ideasta tuote",
      locale: "fi",
      published: true,
    },
    {
      id: "blogi-fi",
      path: "/fi/blogi/",
      title: "Blogi",
      locale: "fi",
      published: true,
    },
    {
      id: "blog-en",
      path: "/blog/",
      title: "Blog",
      locale: "en",
      published: true,
    },
    {
      id: "privacy",
      path: "/privacy/",
      title: "Privacy",
      locale: "en",
      published: true,
    },
    {
      id: "tietosuoja",
      path: "/tietosuoja/",
      title: "Tietosuoja",
      locale: "fi",
      published: true,
    },
  ];

  return allPaths;
};

import React from "react";
import ReactDOM from "react-dom";
import { Link } from "gatsby";
import "styled-components/macro";
import { Helmet } from "react-helmet";
import deepEqual from "deep-equal";
import "styled-components/macro";
import { SiteLanguageContext } from "../templates/black";

const colors = {
  black: "#000",
  white: "#fff",
};

const useDeepCompareMemoize = <T extends any>(value: T) => {
  const ref = React.useRef<T>(null);
  if (!deepEqual(value, ref.current)) ref.current = value;
  return ref.current;
};

export const Tag = ({ children, ...props }) => {
  const props2 = useDeepCompareMemoize(props);
  React.useEffect(() => {
    window["dataLayer"].push(props2);
  }, [props2]);
  return null;
};

export function useLocalStorage(key: string, initialValue: any) {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = React.useCallback(
    value => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        console.log(error);
      }
    },
    [key, storedValue, setStoredValue],
  );
  return [storedValue, setValue];
}

const CookiePopup: React.FC<{ path: string }> = ({ path }) => {
  const lang = React.useContext(SiteLanguageContext);
  const el = React.useMemo(() => document.createElement("div"), []);
  const [accept, setAccept] = useLocalStorage("accept-cookies", false);
  React.useLayoutEffect(() => {
    if (accept) return;
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  }, [el, accept]);
  React.useLayoutEffect(() => {
    if (!accept) return;
    if (typeof (window as any).ga !== "undefined")
      (window as any).ga("send", "pageview", path);
  }, [path, accept]);
  return (
    <>
      {!accept &&
        ReactDOM.createPortal(
          <div
            css={`
              position: fixed;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 4;
              padding: 20px;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              pointer-events: none;
              > div {
                pointer-events: auto;
                background: #fff;
                box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.125);
                padding: 12px 24px;
                border-radius: 5px;
                max-width: 800px;
                color: ${colors.black};
                > p {
                  margin-bottom: 5px;
                }
              }
            `}
          >
            <div>
              {lang === "fi" && (
                <p>
                  Käytämme sivustollamme evästeitä analysoidaksemme sivuston
                  suorituskykyä sekä käyttöä. Evästeet auttavat meitä
                  räätälöimään sekä parantamaan sivustomme sisältöä ja
                  käyttökokemusta.
                </p>
              )}
              {lang === "en" && (
                <p>
                  This site uses cookies to deliver its services and to analyze
                  traffic.
                </p>
              )}
              <div
                css={`
                  text-align: right;
                `}
              >
                <div
                  css={`
                    display: inline-grid;
                    grid-gap: 20px;
                    grid-auto-flow: column;
                    grid-auto-columns: max-content;
                    > a {
                      text-decoration: none;
                      font-size: 18px;
                      line-height: 28px;
                      &:nth-child(2) {
                        background: ${colors.black};
                        padding: 4px 8px;
                        color: ${colors.white};
                        &:active {
                          border: 1px solid ${colors.black};
                          background: transparent;
                          padding: 3px 7px;
                          color: ${colors.black};
                        }
                      }
                      &:nth-child(1) {
                        padding: 4px 8px;
                        color: ${colors.black};
                      }
                    }
                  `}
                >
                  {lang === "fi" && <Link to="/tietosuoja/">Lisätietoa</Link>}
                  {lang === "en" && <Link to="/privacy/">More details</Link>}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href=""
                    onClick={e => {
                      e.preventDefault();
                      setAccept(true);
                    }}
                  >
                    {lang === "fi" && <>Hyväksy</>}
                    {lang === "en" && <>OK</>}
                  </a>
                </div>
              </div>
            </div>
          </div>,
          el,
        )}
      {accept && (
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-BQD0P4X971"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-BQD0P4X971');
            `}
          </script>
        </Helmet>
      )}
    </>
  );
};

const NoSSR = <T extends React.FC<any>>(Component: T) => {
  if (typeof window === "undefined")
    return () => {
      return null;
    };
  return ({ props }) => (
    <>
      <Component {...props} />
    </>
  );
};

export default NoSSR(CookiePopup);

import React from "react";
import { createGlobalStyle } from "styled-components/macro";
import { Link } from "gatsby";
import { FramianLogo } from "../templates/black";
import { useSitemap } from "./sitemap";

const Menu_Item: React.FC<{ open: boolean; index: number; style?: any }> = ({
  index,
  open,
  children,
  style,
}) => {
  const li = React.useRef<HTMLLIElement>(null);
  React.useEffect(() => {
    const el = li.current!;
    if (!open) {
      el.style["opacity"] = "0";
      el.style["transform"] = "translateY(-16px)";
      return;
    }
    const tid = setTimeout(() => {
      el.style["opacity"] = "1";
      el.style["transform"] = "translateY(0)";
    }, 100 * (index + 1));
    return () => {
      clearTimeout(tid);
      el.style["opacity"] = "0";
      el.style["transform"] = "translateY(-16px)";
    };
  }, [open]);
  return (
    <li
      ref={li}
      style={{
        transition: "transform 150ms ease-out, opacity 150ms ease-out",
        ...style,
      }}
    >
      {children}
    </li>
  );
};

const Menu_GlobalStyle = createGlobalStyle`
body.menu-open {
  overflow: hidden;
}
`;

export const Menu: React.FC<{ path: string }> = ({ path }) => {
  const sitemap = useSitemap();
  const footerLinks = sitemap
    .filter(v => {
      if (!v.published) return false;
      if (["/privacy/", "/tietosuoja/"].indexOf(v.path) !== -1) return false;
      if (v.path === "/fi/referenssit/rehaboo/") return false;
      if (v.path === "/references/rehaboo/") return false;
      if (v.path.startsWith("/fi/blogi/") || v.path.startsWith("/blog/"))
        return false;
      return v.locale === sitemap.find(v => v.path === path)?.locale;
  })
    .sort((a, b) => {
      if (a.path === "/fi/teknologiat/headless/") return 1;
      if (b.path === "/fi/teknologiat/headless/") return -1;
      if (a.path === "/ideasta-tuote/" && b.path.startsWith("/fi/")) return 1;
      if (b.path === "/ideasta-tuote/" && a.path.startsWith("/fi/")) return -1;
      const pathA = a.path.split('/').filter(p => p);
      const pathB = b.path.split('/').filter(p => p);
        return pathA.length - pathB.length || pathA.join('/').localeCompare(pathB.join('/'));
  });
  const [open, setOpen] = React.useState(false);
  const [paddingRight, setPaddingRight] = React.useState(0);
  React.useLayoutEffect(() => {
    if (!open) return;
    if (document.body.classList.contains("menu-open")) return;
    const { width: w1 } = document.body.getBoundingClientRect();
    document.body.classList.add("menu-open");
    /* eslint-ignore-next-line */
    document.documentElement.offsetHeight;
    const { width: w2 } = document.body.getBoundingClientRect();
    document.body.style["padding-right"] = `${w2 - w1}px`;
    setPaddingRight(w2 - w1);
    return () => {
      document.body.classList.remove("menu-open");
      document.body.style["padding-right"] = ``;
      setPaddingRight(0);
    };
  }, [open, setPaddingRight]);
  const langLinks = ["en", "fi"]
    .map(locale => {
      const currentPage = sitemap.find(v => v.path === path);
      if (typeof currentPage === "undefined") return undefined;
      const currentID = currentPage.id.replace(/-[a-z]+$/, "");
      const correspondingPath = sitemap.find(
        v => v.id === `${currentID}-${locale}`,
      )?.path;
      if (correspondingPath === path) return undefined;
      const locales = { en: "In English", fi: "Suomeksi" };
      if (typeof correspondingPath !== "undefined")
        return { title: locales[locale], path: correspondingPath };
      if (locale === "fi") return { title: locales[locale], path: "/fi/" };
      return { title: locales[locale], path: "/" };
    })
    .filter(v => typeof v !== "undefined");
  return (
    <>
      <div
        css={`
          position: fixed;
          z-index: 2;
          top: 10px;
        `}
        style={{ right: 10 + paddingRight }}
      >
        <button
          onClick={e => {
            e.preventDefault();
            setOpen(open => !open);
          }}
          css={`
            padding: 4px 6px;
            padding-top: 5px;
            margin: 0;
            border: none;
            outline: none;
            transition: color 150ms ease-out;
            cursor: pointer;
            transition: background 150ms ease-out;
          `}
          style={{
            color: "#000",
            background: !open ? "#fff" : "transparent",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill={"currentColor"}
              d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"
            />
          </svg>
        </button>
      </div>
      <div
        css={`
          position: fixed;
          left: 32px;
          top: 0;
          z-index: 2;
          transition: opacity 150ms ease-out;
        `}
        style={{
          opacity: open ? "1" : "0",
          pointerEvents: !open ? "none" : undefined,
        }}
      >
        <Link
          to="/"
          css={`
            color: #000;
          `}
          onClick={() => {
            setOpen(false);
          }}
        >
          <FramianLogo />
        </Link>
      </div>
      <div
        css={`
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 0.75);
          font-size: 48px;
          line-height: 1;
          transition: opacity 150ms ease-out;
          backdrop-filter: blur(5px);

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            > li {
              font-weight: 700;
              -webkit-text-stroke: 2px #000;
              -webkit-text-fill-color: transparent;
              line-height: 1.25;
              text-align: center;
              font-family: Roboto, sans-serif;
              > a {
                color: #000;
                text-decoration: none;
                &:active {
                  -webkit-text-stroke: 0 currentColor;
                  -webkit-text-fill-color: currentColor;
                }
              }
            }
          }
        `}
        style={{
          opacity: open ? "1" : "0",
          pointerEvents: !open ? "none" : undefined,
          paddingRight: paddingRight,
        }}
      >
        <ul>
          <Menu_Item open={open} index={0} style={{ marginBottom: "0.5em" }}>
            {langLinks.map((v, k, self) => (
              <React.Fragment key={k}>
                <Link
                  to={v.path}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  {v.title}
                </Link>
                {k !== self.length - 1 && <>{" / "}</>}
              </React.Fragment>
            ))}
          </Menu_Item>
          {footerLinks.map((v, k) => (
            <Menu_Item open={open} index={1 + k}>
              <Link
                to={v.path}
                onClick={() => {
                  setOpen(false);
                }}
              >
                {v.title}
              </Link>
            </Menu_Item>
          ))}
        </ul>
      </div>
      <Menu_GlobalStyle />
    </>
  );
};

import React from "react";
import {
  SiteLanguageContext,
  useIsMobile,
  ContentWithSideBox2,
  Heading,
  Container,
} from "../templates/black";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useSitemap } from "./sitemap";

const Footer: React.FC<{ path: string; noCalendar?: boolean }> = ({
  path,
  noCalendar,
}) => {
  const data = useStaticQuery(graphql`
    query {
      koodiaSuomesta_fi: file(relativePath: { eq: "koodiasuomesta_blue.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      koodiaSuomesta_en: file(
        relativePath: { eq: "codefromfinland_blue.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      allSitePage {
        nodes {
          path
        }
      }
    }
  `);
  const sitemap = useSitemap();
  const isMobile = useIsMobile();
  const footerLinks = sitemap
    .filter(v => {
      if (!v.published) return false;
      if (["/privacy/", "/tietosuoja/"].indexOf(v.path) !== -1) return false;
      if (v.path === "/fi/referenssit/rehaboo/") return false;
      if (v.path === "/references/rehaboo/") return false;
      if (v.path.startsWith("/fi/blogi/") || v.path.startsWith("/blog/"))
        return false;
      return v.locale === sitemap.find(v => v.path === path)?.locale;
  })
    .sort((a, b) => {
      if (a.path === "/fi/teknologiat/headless/") return 1;
      if (b.path === "/fi/teknologiat/headless/") return -1;
      if (a.path === "/ideasta-tuote/" && b.path.startsWith("/fi/")) return 1;
      if (b.path === "/ideasta-tuote/" && a.path.startsWith("/fi/")) return -1;
      const pathA = a.path.split('/').filter(p => p);
      const pathB = b.path.split('/').filter(p => p);
        return pathA.length - pathB.length || pathA.join('/').localeCompare(pathB.join('/'));
  });
  const lang = sitemap.find(v => v.path === path)?.locale;
  return (
    <>
      <div
        style={{ color: "#fff", paddingTop: 80, background: "#000" }}
        css={`
          a {
            text-decoration: none;
          }
        `}
      >
        {!noCalendar && (
          <>
            <SiteLanguageContext.Provider value={lang}>
              <ContentWithSideBox2 />
            </SiteLanguageContext.Provider>
            <Heading invert title={"Index"} />
          </>
        )}
        <Container
          style={{
            marginTop: -40,
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(12, 1fr)",
              gridGap: "0px 24px",
            }}
          >
            <div style={{ gridColumn: isMobile ? "2 / 12" : "3 / 11" }}>
              <ul
                style={{
                  fontWeight: 300,
                  fontSize: 42,
                  lineHeight: "64px",
                  marginBottom: 40 + 20,
                  listStyle: "none",
                  padding: 0,
                  wordBreak: "break-all",
                }}
              >
                {footerLinks.map((v, k) => (
                  <li key={k}>
                    <Link to={v.path} style={{ color: "#fff" }}>
                      {v.title}
                    </Link>
                  </li>
                ))}
              </ul>

              <div
                style={{
                  display: "flex",
                  fontFamily: "'Roboto Mono', monospace",
                  fontSize: 16,
                  alignItems: "center",
                  marginBottom: 16,
                }}
              >
                <div style={{ flex: "2" }}>
                  <strong>FRAMIAN Ltd</strong>
                  <br />
                  <br />
                  <a
                    href={`https://maps.google.com/?q=${encodeURIComponent(
                      "Hämeentie 135, 00560 Helsinki",
                    )}`}
                    target="_blank"
                    rel="noopener nofollow"
                    style={{ color: "#fff" }}
                  >
                    Hämeentie 135
                  </a>
                  <br />
                  00560 Helsinki
                  <br />
                  Finland
                  <br />
                  <a
                    href="https://www.google.com/maps/place/60%C2%B012'30.8%22N+24%C2%B058'36.7%22E/@60.2085481,24.9768668,17z/"
                    target="_blank"
                    rel="noopener nofollow"
                    style={{ color: "#fff", wordBreak: "break-all" }}
                  >
                    60.20855, 24.97687
                  </a>
                  <br />
                  <br />
                  <a href="tel:+358400810481" style={{ color: "#fff" }}>
                    +358400810481
                  </a>
                  <br />
                  FI28772969
                </div>
                <div style={{ flex: "1" }}>
                  <a
                    href="https://www.facebook.com/framian"
                    style={{ color: "#fff" }}
                  >
                    _facebook
                  </a>
                  <br />
                  <br />
                  <a
                    href="https://twitter.com/FramianFi"
                    style={{ color: "#fff" }}
                  >
                    _twitter
                  </a>
                  <br />
                  <br />
                  <a
                    href="https://www.instagram.com/framian"
                    style={{ color: "#fff" }}
                  >
                    _instagram
                  </a>
                  <br />
                  <br />
                  <a
                    href="https://www.linkedin.com/company/framian"
                    style={{ color: "#fff" }}
                  >
                    _linkedin
                  </a>
                  <br />
                  <br />
                  <>
                    {lang === "fi" && (
                      <a
                        href="https://thehub.io/startups/framian-oy"
                        style={{ color: "#fff" }}
                      >
                        _työpaikat
                      </a>
                    )}
                    {lang === "en" && (
                      <a
                        href="https://thehub.io/startups/framian-oy"
                        style={{ color: "#fff" }}
                      >
                        _careers
                      </a>
                    )}
                  </>
                  <br />
                  <br />
                  <>
                    {lang === "fi" && (
                      <Link to="/tietosuoja/" style={{ color: "#fff" }}>
                        _tietosuoja
                      </Link>
                    )}
                    {lang === "en" && (
                      <Link to="/privacy/" style={{ color: "#fff" }}>
                        _privacy
                      </Link>
                    )}
                  </>
                </div>
              </div>
              <div
                style={{
                  maxWidth: 240,
                }}
              >
                {lang === "fi" && (
                  <Img
                    fluid={data.koodiaSuomesta_fi.childImageSharp.fluid}
                    alt="Koodia Suomesta"
                  />
                )}
                {lang === "en" && (
                  <Img
                    fluid={data.koodiaSuomesta_en.childImageSharp.fluid}
                    alt="Code from Finland"
                  />
                )}
              </div>
              {lang === "fi" ? (
                <p
                  style={{
                    fontFamily: "'Roboto Mono', monospace",
                    fontSize: 12,
                    lineHeight: "normal",
                    paddingBottom: 80,
                  }}
                >
                  &copy; {new Date().getFullYear()} Framian Oy.
                </p>
              ) : (
                <p
                  style={{
                    fontFamily: "'Roboto Mono', monospace",
                    fontSize: 12,
                    lineHeight: "normal",
                    paddingBottom: 80,
                  }}
                >
                  &copy; {new Date().getFullYear()} Framian Ltd. Agree that you
                  will not use these products for any purposes prohibited by
                  law, including, without limitation, the development, design,
                  manufacture, or production of nuclear, missile, or chemical or
                  biological weapons.
                </p>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
